import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSpinner
} from '@angular/material';

import { OverlayModule } from '@angular/cdk/overlay';

import { AppDeactivateGuardService } from './services/deactivate-guard.service';
import { AppUnsavedChangesDialogComponent } from './components/unsaved-changes-dialog';
import { AppConfirmDialogModule, AppConfirmDialogComponent } from './components/confirm-dialog';

@NgModule({
    imports: [
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        OverlayModule,
        AppConfirmDialogModule
    ],
    declarations: [
        AppUnsavedChangesDialogComponent,
        AppConfirmDialogComponent
    ],
    entryComponents: [
        AppUnsavedChangesDialogComponent,
        AppConfirmDialogComponent,
        MatSpinner
    ],
    providers: [AppDeactivateGuardService]
})
export class AppSharedModule {
}
