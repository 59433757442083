import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { Api } from '../../../shared/types/api';
import { AppApiService, AppService } from '../../../shared/services';

@Injectable()
export class AppEditPostService {

    post: Api.Posts.Post;

    constructor(
        private apiSvc: AppApiService,
        private appSvc: AppService
    ) {
    }

    loadPost(id: number): Observable<Api.Posts.Post> {
        if (this.post && id === this.post.id) {
            return of(this.post);
        }

        this.appSvc.showSpinner();

        return this.apiSvc.getUserPost(id).pipe(tap(post => {
            this.post = post;
        })).pipe(
            finalize(() => this.appSvc.hideSpinner())
        );
    }
}
