import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject, Subscription } from 'rxjs';

import { AppConstants } from '../../../../../shared/constants';
import { Api } from '../../../../../shared/types/api';

@Component({
    selector: 'app-post-list',
    templateUrl: './post-list.component.html',
    styleUrls: ['./post-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppPostListComponent {

    readonly constants = AppConstants;

    @Input() posts: Api.Posts.Post[] = [];
    @Input() total = 0;

    @Output() page: EventEmitter<Partial<PageEvent>> = new EventEmitter();
    @Output() remove: EventEmitter<Api.Posts.Post> = new EventEmitter();
}
