import { NgModule } from '@angular/core';

import { AppBreadcrumbsModule } from '../../../core/components/breadcrumbs';
import { AppSharedModule } from '../../shared/shared.module';
import { AppIntraRoutingModule } from './intra-routing.module';
import { AppEditPostModule } from './post';
import { AppIntraComponent } from './intra.component';

@NgModule({
    declarations: [
        AppIntraComponent
    ],
    imports: [
        AppSharedModule,
        AppIntraRoutingModule,
        AppBreadcrumbsModule,
        AppEditPostModule
    ]
})
export class AppIntraModule {
}
