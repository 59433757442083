import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSpinner } from '@angular/material';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';

import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef,
    SimpleSnackBar
} from '@angular/material';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { BreadcrumbsItem } from '../../../core/components/breadcrumbs';
import {
    AppUnsavedChangesDialogComponent
} from '../components/unsaved-changes-dialog/unsaved-changes-dialog.component';

const SNACKBAR_DURATION = 5000;

export interface SnackBarAction {
    name: string;
    callback?(): void;
}

@Injectable()
export class AppService {

    breadcrumbs: BreadcrumbsItem[] = [];

    private spinnerRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'app-overlay-backdrop',
        positionStrategy: this.overlay.position()
            .global()
           .centerHorizontally()
           .centerVertically()
    });

    private spinnerAttached = false;

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private ngZone: NgZone,
        public dialog: MatDialog,
        private overlay: Overlay
    ) {
    }

    notifyInfo(msg: string, action?: SnackBarAction) {
        this.notify(msg, { ...(action || {}), name: 'Скрыть' });
    }

    notifyError(msg?: string, action?: SnackBarAction) {
        this.notify(msg, { ...(action || {}), name: 'Понятно' }, { panelClass: ['app-snack-bar-error'] });
    }

    /**
     * Execute function when angular zone is stable
     * @param fn function to execute
     */
    executeOnStableZone(fn: () => any) {
        if (this.ngZone.isStable) {
            fn();
        } else {
            this.ngZone.onStable
                .asObservable()
                .pipe(first())
                .subscribe(fn);
        }
    }

    openUnsavedChangesDialog(valid?: boolean): Observable<'cancel' | 'disregard' | 'save'> {
        return this.dialog.open(AppUnsavedChangesDialogComponent, {
            width: '450px',
            data: valid
        }).afterClosed();
    }

    showSpinner() {
        if (!this.spinnerAttached) {
            this.spinnerRef.attach(new ComponentPortal(MatSpinner));

            this.spinnerAttached = true;
        }
    }

    hideSpinner() {
        if (this.spinnerAttached) {
            this.spinnerRef.detach();

            this.spinnerAttached = false;
        }
    }

    private notify(msg: string, action: SnackBarAction, configOverride?: MatSnackBarConfig) {
        const baseConfig: MatSnackBarConfig = {
            duration: SNACKBAR_DURATION,
            panelClass: ['app-snack-bar-info'],
            verticalPosition: 'top'
        };

        const ref: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
            msg,
            action.name,
            { ...baseConfig, ...(configOverride || {}) }
        );

        if (action && action.callback) {
            ref.onAction().pipe(first()).subscribe(action.callback.bind(this));
        }
    }
}
