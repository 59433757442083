import {
    Component,
    ChangeDetectionStrategy
} from '@angular/core';

import { AppService } from '../../shared/services/app.service';

@Component({
    templateUrl: './intra.component.html',
    styleUrls: ['./intra.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppIntraComponent {

    get breadcrumbs() {
        return this.appSvc.breadcrumbs;
    }

    constructor(
        private appSvc: AppService
    ) {
    }
}
