import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Api } from '../types/api';

const apiUrl = 'https://dev.insta.lingvonavi.com/api/v1';

@Injectable()
export class AppApiService {

    private languages: Api.Languages.GetAll.Response;
    private levels: Api.Levels.GetAll.Response;

    constructor(
        private http: HttpClient
    ) {
    }

    signUp(data: Api.Auth.Register.Request): Observable<Api.Auth.Register.Response> {
        return this.makeJsonRequest<Api.Auth.Register.Response>(
            'POST', ['auth', 'register'], data
        );
    }

    signIn(data: Api.Auth.Login.Request): Observable<Api.Auth.Login.Response> {
        return this.makeJsonRequest<Api.Auth.Login.Response>(
            'POST', ['auth', 'login'], data
        );
    }

    signOut(): Observable<void> {
        return this.makeJsonRequest<void>('DELETE', ['auth', 'logout']);
    }

    getAllLanguages(): Observable<Api.Languages.GetAll.Response> {
        if (this.languages) {
            return of(this.languages);
        }

        return this.makeJsonRequest<Api.Levels.GetAll.Response>(
            'GET', ['languages']
        ).pipe(map(resp => {
            this.languages = resp;

            return this.languages;
        }));
    }

    getAllLevels(): Observable<Api.Levels.GetAll.Response> {
        if (this.levels) {
            return of(this.levels);
        }

        return this.makeJsonRequest<Api.Levels.GetAll.Response>(
            'GET', ['levels']
        ).pipe(map(resp => {
            this.levels = resp;

            return this.levels;
        }));
    }

    createPost(data: Api.Posts.Post): Observable<Api.Posts.Post> {
        return this.makeJsonRequest<Api.Posts.Post>(
            'POST', ['posts'], data
        );
    }

    getUserPosts(): Observable<Api.Posts.Post[]> {
        return this.makeJsonRequest<Api.Posts.Post[]>('GET', ['posts']);
    }

    getUserPost(id: number): Observable<Api.Posts.Post> {
        return this.makeJsonRequest<Api.Posts.Post>('GET', ['posts', String(id)]);
    }

    publishPost(id: number): Observable<Api.Posts.Post> {
        return this.makeJsonRequest<Api.Posts.Post>(
            'PUT', ['posts', String(id), 'publish']
        );
    }

    savePost(data: Api.Posts.Post): Observable<void> {
        return this.makeJsonRequest<void>(
            'PUT', ['posts', String(data.id)], data
        );
    }

    deletePost(id: number): Observable<void> {
        return this.makeJsonRequest<void>(
            'DELETE', ['posts', String(id)]
        );
    }

    createSample(postId: number, data: Api.Posts.Sample): Observable<Api.Posts.Sample> {
        return this.makeJsonRequest<Api.Posts.Sample>(
            'POST', ['samples', 'post', String(postId)], data
        );
    }

    getSample(id: number): Observable<Api.Posts.Sample> {
        return this.makeJsonRequest<Api.Posts.Sample>('GET', ['samples', String(id)]);
    }

    saveSample(data: Api.Posts.Sample): Observable<void> {
        return this.makeJsonRequest<void>(
            'PUT', ['samples', String(data.id)], data
        );
    }

    deleteSample(id: number): Observable<void> {
        return this.makeJsonRequest<void>(
            'DELETE', ['samples', String(id)]
        );
    }

    createVoice(sampleId: number, data: Api.Voice): Observable<Api.Voice> {
        return this.makeJsonRequest<Api.Voice>(
            'POST', ['voices', 'sample', String(sampleId)], data
        );
    }

    deleteVoice(id: number): Observable<void> {
        return this.makeJsonRequest<void>(
            'DELETE', ['voices', String(id)]
        );
    }

    createPostTag(postId: number, data: Api.Tag): Observable<void> {
        return this.makeJsonRequest<void>(
            'POST', ['tags', 'post', String(postId)], data
        );
    }

    createSampleTag(sampleId: number, data: Api.Tag): Observable<void> {
        return this.makeJsonRequest<void>(
            'POST', ['tags', 'sample', String(sampleId)], data
        );
    }

    private makeJsonRequest<T>(method: 'POST' | 'GET' | 'DELETE' | 'PUT', urlSegments: string[], data?: any): Observable<T> {
        const url = this.getUrl(urlSegments);

        let response: Observable<any>;

        switch (method) {
            case 'POST':
                response = this.http.post<T>(url, data);

                break;
            case 'PUT':
                response = this.http.put<T>(url, data);

                break;
            case 'GET':
                response = this.http.get<T>(url);

                break;
            case 'DELETE':
                response = this.http.delete<T>(url);

                break;
            default:
                throw new Error(`Unsupported HTTP method ${method}`);

                break;
        }

        return response.pipe(catchError(error => {
            const typedError = this.getError(error);

            return throwError(typedError);
        }));
    }

    private getUrl(segments: string[]): string {
        return [apiUrl, ...segments].join('/');
    }

    private getError(error: HttpErrorResponse): Api.Error {
        if (error instanceof ErrorEvent) {
            return {
                code: error.status,
                error: 'Client-side or network error',
                message: error.message,
                time: new Date()
            };
        } else {
            return {
                code: error.error.code,
                error: error.error.error,
                message: error.error.message,
                time: new Date(error.error.time)
            };
        }
    }
}
