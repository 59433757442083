import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject, Subscription } from 'rxjs';

import { AppConstants } from '../../../../../shared/constants';
import { Api } from '../../../../../shared/types/api';

@Component({
    selector: 'app-sample-list',
    templateUrl: './sample-list.component.html',
    styleUrls: ['./sample-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSampleListComponent {

    readonly constants = AppConstants;

    @Input() samples: Api.Posts.Post[] = [];
    @Input() total = 0;
    @Input() postId: number;
    @Input() published: boolean;

    @Output() page: EventEmitter<Partial<PageEvent>> = new EventEmitter();
    @Output() remove: EventEmitter<Api.Posts.Sample> = new EventEmitter();
}
