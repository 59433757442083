import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppSessionService } from '../shared/services/session.service';

const COOKIE_HEADER_NAME = 'Authorization';

@Injectable()
export class AppSessionHttpInterceptor implements HttpInterceptor {
    constructor(
        private sessionSvc: AppSessionService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.sessionSvc.isSignedIn) {
            request = request.clone({
                setHeaders: {
                    [COOKIE_HEADER_NAME]: this.sessionSvc.cookie
                }
            });
        }

        return next.handle(request).pipe(catchError(error => {
            if (error && error.error && /^JWT expired/.test(error.error.message)) {
                this.sessionSvc.expired$.next();
            }

            return throwError(error);
        }));
    }
}
