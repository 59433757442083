import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';

import { of, BehaviorSubject } from 'rxjs';
import { tap, map, catchError, finalize, first } from 'rxjs/operators';

import * as moment from 'moment';

import { UnsavedChanges, Api } from '../../../shared/types';
import { AppApiService } from '../../../shared/services';
import { AppService } from '../../../shared/services/app.service';
import { AppConfirmDialogComponent } from '../../../shared/components/confirm-dialog';
import { AppEditPostService } from './edit-post.service';

@Component({
    templateUrl: './edit-post.component.html',
    styleUrls: ['./edit-post.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppEditPostComponent implements UnsavedChanges {
    readonly separatorKeysCodes = [ENTER, COMMA];

    readonly formGroup = this.formBuilder.group({
        name: [{
            value: this.post.text,
            disabled: this.post.published
        }, [
            Validators.required,
            Validators.minLength(10)
        ]],
        foreignLanguage: [{
            value: this.post.foreignLanguage.id,
            disabled: this.post.published
        }, [
            Validators.required
        ]],
        nativeLanguage: [{
            value: this.post.nativeLanguage.id,
            disabled: this.post.published
        }, [
            Validators.required
        ]]
    });

    readonly languageOptions$: BehaviorSubject<Api.EntityOption[]> = new BehaviorSubject([]);

    createdTimestamp: moment.Moment;
    publishedTimestamp: moment.Moment;

    get post() {
        return this.postSvc.post;
    }
    get name() {
        return this.formGroup.get('name');
    }
    get foreignLanguage() {
        return this.formGroup.get('foreignLanguage');
    }
    get nativeLanguage() {
        return this.formGroup.get('nativeLanguage');
    }

    constructor(
        private cdRef: ChangeDetectorRef,
        private apiSvc: AppApiService,
        private appSvc: AppService,
        private postSvc: AppEditPostService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog
    ) {
        this.appSvc.breadcrumbs = [
            { label: 'Все темы', routerLink: ['/dashboard/post'] },
            { label: this.post.text }
        ];

        moment.locale('ru');

        this.createdTimestamp = moment(this.post.createdDate);

        if (this.post.published) {
            this.publishedTimestamp = moment(this.post.published);
        }

        this.appSvc.showSpinner();
        this.apiSvc.getAllLanguages().pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            list => this.languageOptions$.next(list),
            error => this.appSvc.notifyError(error.message)
        );
    }

    unsavedChangesPresent() {
        return this.formGroup.dirty;
    }

    unsavedChangesValid() {
        return this.formGroup.valid;
    }

    saveUnsavedChanges() {
        return this.savePost().pipe(map(
            () => true
        )).pipe(
            catchError(
                () => of(false)
            )
        );
    }

    onSave() {
        this.savePost().subscribe();
    }

    onPublish() {
        const dialog = this.dialog.open(AppConfirmDialogComponent, {
            width: '350px',
            autoFocus: true,
            data: 'После публкации тема будет доступна только в режиме чтения. Вы уверенны что хотите опублликовать тему?'
        });

        dialog.afterClosed().pipe(first()).subscribe(
            confirmed => confirmed && this.publishPost()
        );
    }

    addTag(event: MatChipInputEvent) {
        const input = event.input;
        const value = (event.value || '').trim();

        if (value) {
            const tag = { name: value };

            this.appSvc.showSpinner();
            this.apiSvc.createPostTag(this.post.id, tag).pipe(
                finalize(() => this.appSvc.hideSpinner())
            ).subscribe(
                () => {
                    this.post.tags.push(tag);
                    this.cdRef.markForCheck();
                },
                error => this.appSvc.notifyError(error.message)
            );
        }

        if (input) {
            input.value = '';
        }
    }

    removeTag(idx: number): void {
        this.post.tags.splice(idx, 1);
        this.cdRef.markForCheck();
    }

    private savePost() {
        const data = {
            id: this.post.id,
            text: this.formGroup.value.name,
            foreignLanguage: this.languageOptions$.value.find(lang => lang.id === this.foreignLanguage.value),
            nativeLanguage: this.languageOptions$.value.find(lang => lang.id === this.nativeLanguage.value)
        };

        this.appSvc.showSpinner();

        return this.apiSvc.savePost(data).pipe(tap(
            resp => {
                this.appSvc.notifyInfo('Изменения успешно сохранены.');

                this.postSvc.post = { ...this.post, ...data };

                this.formGroup.markAsPristine();
                this.cdRef.markForCheck();
            },
            error => this.appSvc.notifyError(error.message)
        )).pipe(
            finalize(() => this.appSvc.hideSpinner())
        );
    }

    private publishPost() {
        this.appSvc.showSpinner();
        this.apiSvc.publishPost(this.post.id).pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            data => {
                this.post.published = data.published;
                this.publishedTimestamp = moment(this.post.published);
                this.formGroup.disable();

                this.cdRef.markForCheck();
            },
            error => this.appSvc.notifyError(error.message)
        );
    }
}
