import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MatIconModule,
    MatButtonModule,
    MatDialogModule
} from '@angular/material';

@NgModule({
    imports: [
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule
    ]
})
export class AppConfirmDialogModule {
}
