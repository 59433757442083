import { NgModule } from '@angular/core';

import { AppSharedModule } from '../../../../shared/shared.module';
import { AppDictTabRoutingModule } from './dict-routing.module';
import { AppDictTabComponent } from './dict.component';

@NgModule({
    declarations: [
        AppDictTabComponent
    ],
    imports: [
        AppSharedModule,
        AppDictTabRoutingModule
    ]
})
export class AppDictTabModule {
}
