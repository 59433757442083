import { NgModule } from '@angular/core';

import {
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule
} from '@angular/material';

import { AppSharedModule } from '../../../shared/shared.module';
import { AppSampleModule } from './sample';
import { AppEditPostRoutingModule } from './edit-post-routing.module';
import { AppEditPostService } from './edit-post.service';
import { AppEditPostActivateGuardService } from './edit-post-activate-guard.service';
import { AppEditPostComponent } from './edit-post.component';

@NgModule({
    declarations: [
        AppEditPostComponent
    ],
    imports: [
        AppSharedModule,
        AppEditPostRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        AppSampleModule,
        MatChipsModule
    ],
    providers: [
        AppEditPostService,
        AppEditPostActivateGuardService
    ]
})
export class AppEditPostModule {
}
