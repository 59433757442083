import { PageEvent } from '@angular/material/paginator';

export class AppConstants {
    static icons = [
        'th',
        'facebook',
        'vk',
        'odnoklassniki',
        'google',
        'russia',
        'login',
        'user'
    ];

    static defaultPageConfig: Partial<PageEvent> = { pageSize: 3, pageIndex: 0 };
}
