import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

const COOKIE_LOCAL_STORAGE_KEY = 'lingvo-saas-session-token';

@Injectable()
export class AppSessionService {
    hasAvatar = false;

    expired$: ReplaySubject<void> = new ReplaySubject(1);

    get cookie() {
        return localStorage.getItem(COOKIE_LOCAL_STORAGE_KEY) || '';
    }

    get isSignedIn() {
        return Boolean(this.cookie);
    }

    signIn(cookieName: string, cookieValue: string) {
        localStorage.setItem(COOKIE_LOCAL_STORAGE_KEY, `${cookieName} ${cookieValue}`);
    }

    signOut() {
        localStorage.setItem(COOKIE_LOCAL_STORAGE_KEY, '');
    }
}
