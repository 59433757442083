import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router
} from '@angular/router';

import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppService } from '../../../shared/services/app.service';
import { AppEditPostService } from './edit-post.service';

@Injectable()
export class AppEditPostActivateGuardService implements CanActivate {

    constructor(
        private router: Router,
        private postSvc: AppEditPostService,
        private appSvc: AppService
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (!route.params.id) {
            return of(true);
        }

        return this.postSvc.loadPost(route.params.id).pipe(
            map(() => true)
        ).pipe(
            catchError(error => {
                this.appSvc.notifyError(error.message);

                this.router.navigate(['/dashboard/post']);

                return of(false);
            })
        );
    }
}
