import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppIntraComponent } from './intra.component';

const routes: Routes = [{
    path: 'intra', component: AppIntraComponent,
    children: [
        {
            path: 'post/:id',
            loadChildren: () => import('./post/edit-post.module').then(m => m.AppEditPostModule)
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppIntraRoutingModule { }
