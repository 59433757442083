import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppDashboardComponent } from './dashboard.component';

const routes: Routes = [{
    path: 'dashboard',
    component: AppDashboardComponent,
    children: [
        {
            path: 'class',
            loadChildren: () => import('./tabs/class/class.module').then(m => m.AppClassTabModule)
        },
        {
            path: 'dictionary',
            loadChildren: () => import('./tabs/dict/dict.module').then(m => m.AppDictTabModule)
        },
        {
            path: 'grammar',
            loadChildren: () => import('./tabs/grammar/grammar.module').then(m => m.AppGrammarTabModule)
        },
        {
            path: 'post',
            loadChildren: () => import('./tabs/post/post.module').then(m => m.AppPostTabModule)
        },
        {
            path: '',
            redirectTo: 'class',
            pathMatch: 'full'
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppDashboardRoutingModule { }
