import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './unsaved-changes-dialog.component.html',
    styleUrls: ['./unsaved-changes-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppUnsavedChangesDialogComponent {

    constructor(
        private dialog: MatDialogRef<AppUnsavedChangesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public valid: boolean
    ) {
    }

    onSave() {
        this.dialog.close('save');
    }

    onDisregard() {
        this.dialog.close('disregard');
    }

    onCancel() {
        this.dialog.close('cancel');
    }
}
