import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject } from 'rxjs';
import { first, finalize } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { AppConstants } from '../../../../shared/constants';
import { Api } from '../../../../shared/types/api';
import { AppApiService } from '../../../../shared/services';
import { AppService } from '../../../../shared/services/app.service';
import { AppCreateSampleDialogComponent } from './create/create-sample-dialog.component';
import { AppConfirmDialogComponent } from '../../../../shared/components/confirm-dialog';
import { AppEditPostService } from '../edit-post.service';

@Component({
    templateUrl: './sample.component.html',
    styleUrls: ['./sample.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSampleComponent {

    readonly samplesPage$: BehaviorSubject<Api.Posts.Sample[]> = new BehaviorSubject([]);

    constructor(
        public postSvc: AppEditPostService,
        private apiSvc: AppApiService,
        private appSvc: AppService,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef
    ) {
        this.appSvc.breadcrumbs = [
            { label: 'Все темы', routerLink: ['/dashboard/post'] },
            { label: this.postSvc.post.text, routerLink: ['/intra/post', this.postSvc.post.id] },
            { label: 'Юниты' }
        ];

        this.setSamplesPage();
    }

    openCreateSampleDialog() {
        const dialog = this.dialog.open(AppCreateSampleDialogComponent, {
            width: '550px',
            autoFocus: true
        });

        dialog.afterClosed().pipe(first()).subscribe(
            sample => sample && this.saveSample(sample)
        );
    }

    setSamplesPage(pageConfig: Partial<PageEvent> = AppConstants.defaultPageConfig) {
        const from = pageConfig.pageIndex * pageConfig.pageSize;
        const to = from + pageConfig.pageSize;

        this.samplesPage$.next(this.postSvc.post.samples.slice(from, to));
    }

    onRemove(sample: Api.Posts.Post) {
        this.dialog.open(AppConfirmDialogComponent, {
            width: '350px',
            autoFocus: true,
            data: 'Вы уверенны что хотите удалить юнит?'
        }).afterClosed().pipe(first()).subscribe(confirmed => {
            if (confirmed) {
                this.appSvc.showSpinner();
                this.apiSvc.deleteSample(sample.id).pipe(
                    finalize(() => this.appSvc.hideSpinner())
                ).subscribe(
                    () => {
                        const idx = this.postSvc.post.samples.findIndex(rec => rec.id === sample.id);

                        this.postSvc.post.samples.splice(idx, 1);

                        this.setSamplesPage();
                    },
                    error => this.appSvc.notifyError(error.message)
                );
            }
        });
    }

    openFilters() {
        /* Not implemented */
    }

    private saveSample(sample: Api.Posts.Sample) {
        this.appSvc.showSpinner();
        this.apiSvc.createSample(this.postSvc.post.id, sample).pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            data => {
                this.postSvc.post.samples = Array.apply([], [data, ...this.postSvc.post.samples]);
                this.setSamplesPage();

                this.cdRef.markForCheck();
            },
            error => this.appSvc.notifyError(error.message)
        );
    }
}
