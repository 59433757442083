import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppDeactivateGuardService } from '../../../shared/services/deactivate-guard.service';
import { AppEditPostComponent } from './edit-post.component';
import { AppEditPostActivateGuardService } from './edit-post-activate-guard.service';

const routes: Routes = [
    {
        path: '',
        component: AppEditPostComponent,
        canActivate: [AppEditPostActivateGuardService],
        canDeactivate: [AppDeactivateGuardService]
    },
    {
        path: 'sample',
        loadChildren: () => import('./sample/sample.module').then(m => m.AppSampleModule),
        canActivate: [AppEditPostActivateGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppEditPostRoutingModule { }
