import { NgModule } from '@angular/core';

import {
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
} from '@angular/material';

import { AppSharedModule } from '../../shared/shared.module';
import { AppLoginDialogComponent } from './login-dialog.component';

@NgModule({
    declarations: [
        AppLoginDialogComponent
    ],
    imports: [
        AppSharedModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule
    ],
    exports: [AppLoginDialogComponent],
    entryComponents: [AppLoginDialogComponent]
})
export class AppLoginModule {
}
