import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppSampleComponent } from './sample.component';

const routes: Routes = [
    {
        path: '',
        component: AppSampleComponent
    },
    {
        path: ':id',
        loadChildren: () => import('./edit/edit-sample.module').then(m => m.AppEditSampleModule)
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppSampleRoutingModule { }
