import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatOption } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { Api } from '../../../../../shared/types/api';
import { AppApiService } from '../../../../../shared/services';
import { AppService } from '../../../../../shared/services/app.service';

@Component({
    selector: 'app-create-sample-dialog',
    templateUrl: './create-sample-dialog.component.html',
    styleUrls: ['./create-sample-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppCreateSampleDialogComponent {
    formGroup = this.formBuilder.group({
        sentence: ['', [Validators.required]],
        translation: [''],
        description: [''],
        level: [0]
    });

    levelOptions$: BehaviorSubject<Api.EntityOption[]> = new BehaviorSubject([]);

    get sentence() {
        return this.formGroup.get('sentence');
    }

    private get level() {
        return this.formGroup.get('level');
    }

    constructor(
        private apiSvc: AppApiService,
        private appSvc: AppService,
        private dialog: MatDialogRef<AppCreateSampleDialogComponent>,
        private formBuilder: FormBuilder
    ) {
        this.appSvc.showSpinner();
        this.apiSvc.getAllLevels().pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            list => this.levelOptions$.next(list),
            error => this.appSvc.notifyError(error.message)
        );
    }

    onSave() {
        this.dialog.close({
            level: this.getSelectedLevel(),
            value: this.formGroup.value.sentence,
            translation: this.formGroup.value.translation,
            description: this.formGroup.value.description
        });
    }

    onCancel() {
        this.dialog.close();
    }

    private getSelectedLevel() {
        return this.level.value ? this.levelOptions$.value.find(lang => lang.id === this.level.value) : null;
    }
}
