import {
    Component,
    ChangeDetectionStrategy
} from '@angular/core';

@Component({
    templateUrl: './dict.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDictTabComponent {
}
