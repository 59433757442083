import {
    Component,
    ChangeDetectionStrategy,
    Input,
    HostListener,
    Output,
    EventEmitter
} from '@angular/core';

import { Router } from '@angular/router';

@Component({
    selector: 'app-sample-card',
    templateUrl: './sample-card.component.html',
    styleUrls: ['./sample-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSampleCardComponent {

    @Input() id: number;
    @Input() postId: number;
    @Input() postPublished = false;
    @Input() foreignLanguage: string;
    @Input() nativeLanguage: string;
    @Input() level: string;
    @Input() sentence: string;
    @Input() translation = '';
    @Input() description = '';
    @Input() published: boolean;

    @Output() remove: EventEmitter<void> = new EventEmitter();

    constructor(
        private router: Router
    ) {
    }

    onRemove(event: Event) {
        event.stopPropagation();

        this.remove.emit();
    }

    @HostListener('click') onClick() {
        this.router.navigate(['/intra/post', this.postId, 'sample', this.id]);
    }
}
