import {
    Component,
    ChangeDetectionStrategy,
    Inject
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ConfirmDialogData {
    message: string;
    onConfirm: () => void;
    onDismiss: () => void;
}

@Component({
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfirmDialogComponent {

    constructor(
        private dialog: MatDialogRef<AppConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string
    ) {
    }

    onConfirm() {
        this.dialog.close(true);
    }

    onCancel() {
        this.dialog.close(false);
    }
}
