import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';

import {
    MatMenuModule,
    MatCardModule,
} from '@angular/material';

import { AppSharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppLoginModule } from './components/login/login.module';
import { AppDashboardModule } from './components/dashboard';
import { AppIntraModule } from './components/intra';
import { AppComponent } from './app.component';
import { AppSessionHttpInterceptor } from './auth';
import { AppSessionService } from './shared/services/session.service';
import { AppApiService } from './shared/services/api.service';
import { AppService } from './shared/services/app.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        MatMenuModule,
        MatCardModule,
        AppRoutingModule,
        AppSharedModule,
        AppLoginModule,
        AppDashboardModule,
        AppIntraModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppSessionHttpInterceptor,
            multi: true
        },
        AppSessionService,
        AppApiService,
        AppService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
