import {
    Component,
    ChangeDetectionStrategy,
    Input,
    HostListener,
    Output,
    EventEmitter
} from '@angular/core';

import { Router } from '@angular/router';

import * as moment from 'moment';

@Component({
    selector: 'app-post-card',
    templateUrl: './post-card.component.html',
    styleUrls: ['./post-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppPostCardComponent {
    @Input() id: number;
    @Input() name: string;
    @Input() foreignLanguage: string;
    @Input() nativeLanguage: string;

    @Input() set created(created: string) {
        if (created) {
            this.createdTimestamp = moment(created);
        }
    }

    @Input() set published(published: string) {
        if (published) {
            this.publishedTimestamp = moment(published);
        }
    }

    @Output() remove: EventEmitter<void> = new EventEmitter();

    createdTimestamp: moment.Moment;
    publishedTimestamp: moment.Moment;

    constructor(
        private router: Router
    ) {
        moment.locale('ru');
    }

    onRemove(event: Event) {
        event.stopPropagation();

        this.remove.emit();
    }

    @HostListener('click') onClick() {
        this.router.navigate(['/intra/post', this.id]);
    }
}
