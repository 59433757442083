import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BehaviorSubject, Subscription } from 'rxjs';
import { first, finalize } from 'rxjs/operators';

import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';

import { AppSessionService } from './shared/services/session.service';
import { AppApiService } from './shared/services/api.service';
import { AppService } from './shared/services/app.service';
import { AppConstants } from './shared/constants';
import { Api } from './shared/types/api';
import { AppLoginDialogComponent } from './components/login/login-dialog.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {

    private subs: Subscription[] = [];

    constructor(
        public sessionSvc: AppSessionService,
        private apiSvc: AppApiService,
        private appSvc: AppService,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        AppConstants.icons.forEach(icon => iconRegistry.addSvgIcon(
                icon,
                sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)
            )
        );

        this.subs.push(this.sessionSvc.expired$.subscribe(() => {
            this.sessionSvc.signOut();
            this.appSvc.notifyError(
                'Время действия Вашей сессии истекло. Авторизируйтесь снова чтобы получить полный доступ к функционалу полтала.'
            );
            this.cdRef.markForCheck();
        }));
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub && sub.unsubscribe());
    }

    openLoginDialog() {
        const dialog = this.dialog.open(AppLoginDialogComponent, {
            width: '350px',
            autoFocus: true
        });

        dialog.afterClosed().pipe(first()).subscribe(() => this.cdRef.markForCheck());
    }

    onSignOut() {
        this.appSvc.showSpinner();

        this.apiSvc.signOut().pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            resp => {
                this.sessionSvc.signOut();
                this.appSvc.notifyInfo(
                    'Авторизируйтесь чтобы получить полный доступ к функционалу полтала.'
                );
                this.cdRef.markForCheck();
            },
            error => this.appSvc.notifyError(error.message)
        );
    }
}
