import {
    Component,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';

import { BreadcrumbsItem } from './shared/breadcrumbs-item.interface';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBreadcrumbsComponent {

    @Input() value: BreadcrumbsItem[] = [];
}
