import {
    Component,
    ChangeDetectionStrategy
} from '@angular/core';

@Component({
    templateUrl: './class.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppClassTabComponent {
}
