import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatOption } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { AppApiService } from '../../../../../shared/services';
import { AppService } from '../../../../../shared/services/app.service';
import { Api } from '../../../../../shared/types';

@Component({
    selector: 'app-create-post-dialog',
    templateUrl: './create-post-dialog.component.html',
    styleUrls: ['./create-post-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppCreatePostDialogComponent {

    formGroup = this.formBuilder.group({
        name: ['', [
            Validators.required,
            Validators.minLength(10)
        ]],
        foreignLanguage: ['', [Validators.required]],
        nativeLanguage: ['', [Validators.required]]
    });

    languageOptions$: BehaviorSubject<Api.EntityOption[]> = new BehaviorSubject([]);

    get name() {
        return this.formGroup.get('name');
    }
    get foreignLanguage() {
        return this.formGroup.get('foreignLanguage');
    }
    get nativeLanguage() {
        return this.formGroup.get('nativeLanguage');
    }

    constructor(
        private apiSvc: AppApiService,
        private appSvc: AppService,
        private dialog: MatDialogRef<AppCreatePostDialogComponent>,
        private formBuilder: FormBuilder
    ) {
        this.appSvc.showSpinner();
        this.apiSvc.getAllLanguages().pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            list => this.languageOptions$.next(list),
            error => this.appSvc.notifyError(error.message)
        );
    }

    onSave() {
        this.appSvc.showSpinner();
        this.apiSvc.createPost({
            text: this.formGroup.value.name,
            foreignLanguage: this.formGroup.value.foreignLanguage,
            nativeLanguage: this.formGroup.value.nativeLanguage
        }).pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            resp => this.dialog.close(resp),
            error => this.appSvc.notifyError(error.message)
        );
    }

    onCancel() {
        this.dialog.close();
    }
}
