 import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    OnDestroy
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject, Subscription } from 'rxjs';
import { first, finalize } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { AppConstants } from '../../../../shared/constants';
import { Api } from '../../../../shared/types/api';
import { AppApiService } from '../../../../shared/services';
import { AppService } from '../../../../shared/services/app.service';
import { AppCreatePostDialogComponent } from './create/create-post-dialog.component';
import { AppConfirmDialogComponent } from '../../../../shared/components/confirm-dialog';

@Component({
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppPostTabComponent implements OnInit, OnDestroy {

    readonly postsPage$: BehaviorSubject<Api.Posts.Post[]> = new BehaviorSubject([]);
    readonly posts$: BehaviorSubject<Api.Posts.Post[]> = new BehaviorSubject([]);

    private readonly subs: Subscription[] = [];

    constructor(
        private dialog: MatDialog,
        private apiSvc: AppApiService,
        private appSvc: AppService
    ) {
    }

    ngOnInit() {
        this.subs.push(this.posts$.subscribe(() => this.setPostsPage()));

        this.appSvc.showSpinner();
        this.apiSvc.getUserPosts().pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            list => this.posts$.next(list),
            error => this.appSvc.notifyError(error.message)
        );
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub && sub.unsubscribe());
    }

    openCreatePostDialog() {
        const dialog = this.dialog.open(AppCreatePostDialogComponent, {
            width: '550px',
            autoFocus: true
        });

        dialog.afterClosed().pipe(first()).subscribe(post => {
            if (post) {
                this.posts$.next([post, ...this.posts$.value]);
            }
        });
    }

    setPostsPage(pageConfig: Partial<PageEvent> = AppConstants.defaultPageConfig) {
        const from = pageConfig.pageIndex * pageConfig.pageSize;
        const to = from + pageConfig.pageSize;

        this.postsPage$.next(
            this.posts$.value.slice(from, to)
        );
    }

    onRemove(post: Api.Posts.Post) {
        this.dialog.open(AppConfirmDialogComponent, {
            width: '350px',
            autoFocus: true,
            data: 'Вы уверенны что хотите удалить тему вместе с юнитами?'
        }).afterClosed().pipe(first()).subscribe(confirmed => {
            if (confirmed) {
                this.appSvc.showSpinner();
                this.apiSvc.deletePost(post.id).pipe(
                    finalize(() => this.appSvc.hideSpinner())
                ).subscribe(
                    () => {
                        const posts: Api.Posts.Post[] = Array.apply([], this.posts$.value);
                        const idx = posts.findIndex(rec => rec.id === post.id);

                        posts.splice(idx, 1);

                        this.posts$.next(posts);
                    },
                    error => this.appSvc.notifyError(error.message)
                );
            }
        });
    }

    openPostFilters() {
        /* Not implemented */
    }
}
