import { NgModule } from '@angular/core';

import {
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatPaginatorModule,
    MatCardModule
} from '@angular/material';

import { AppSharedModule } from '../../../../shared/shared.module';
import { AppSampleRoutingModule } from './sample-routing.module';
import { AppSampleComponent } from './sample.component';
import { AppCreateSampleDialogComponent } from './create/create-sample-dialog.component';
import { AppSampleListComponent } from './list/sample-list.component';
import { AppSampleCardComponent } from './card/sample-card.component';

@NgModule({
    declarations: [
        AppSampleComponent,
        AppCreateSampleDialogComponent,
        AppSampleListComponent,
        AppSampleCardComponent
    ],
    imports: [
        AppSharedModule,
        AppSampleRoutingModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatPaginatorModule,
        MatCardModule
    ],
    entryComponents: [AppCreateSampleDialogComponent]
})
export class AppSampleModule {
}
