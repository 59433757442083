import { NgModule } from '@angular/core';

import { AppSharedModule } from '../../../../shared/shared.module';
import { AppClassTabRoutingModule } from './class-routing.module';
import { AppClassTabComponent } from './class.component';

@NgModule({
    declarations: [
        AppClassTabComponent
    ],
    imports: [
        AppSharedModule,
        AppClassTabRoutingModule
    ]
})
export class AppClassTabModule {
}
