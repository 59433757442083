import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AppService } from '../services/app.service';
import { UnsavedChanges } from '../types/unsaved-changes.interface';

@Injectable()
export class AppDeactivateGuardService implements CanDeactivate<UnsavedChanges> {

    constructor(
        private appSvc: AppService
    ) { }

    canDeactivate(component: UnsavedChanges): Observable<boolean> {
        if (!component.unsavedChangesPresent()) {
            return of(true);
        }

        return this.appSvc.openUnsavedChangesDialog(component.unsavedChangesValid()).pipe(
            mergeMap(result => {
                switch (result) {
                    case 'cancel':
                        return of(false);
                    case 'disregard':
                        return of(true);
                    case 'save':
                        return component.saveUnsavedChanges();
                    default:
                        return of(true);
                }
            })
        );
    }
}
