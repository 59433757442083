import {
    Component,
    ChangeDetectionStrategy
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDashboardComponent {
    activeLinkIndex = -1;

    navItems = [
        {
            label: 'Мои классы',
            link: './class',
            index: 0
        },
        {
            label: 'Словарь',
            link: './dictionary',
            index: 1
        },
        {
            label: 'Граматика',
            link: './grammar',
            index: 2
        },
        {
            label: 'Темы',
            link: './post',
            index: 3
        }
    ];

    constructor(
        router: Router
    ) {
        router.events.subscribe(() => {
            this.activeLinkIndex = this.navItems.indexOf(
                this.navItems.find(tab => tab.link === '.' + router.url)
            );
        });
    }
}
