import { NgModule } from '@angular/core';

import { AppSharedModule } from '../../../../shared/shared.module';
import { AppGrammarTabRoutingModule } from './grammar-routing.module';
import { AppGrammarTabComponent } from './grammar.component';

@NgModule({
    declarations: [
        AppGrammarTabComponent
    ],
    imports: [
        AppSharedModule,
        AppGrammarTabRoutingModule
    ]
})
export class AppGrammarTabModule {
}
