import { NgModule } from '@angular/core';

import {
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatPaginatorModule,
    MatCardModule
} from '@angular/material';

import { AppSharedModule } from '../../../../shared/shared.module';
import { AppPostTabRoutingModule } from './post-routing.module';
import { AppPostTabComponent } from './post.component';
import { AppCreatePostDialogComponent } from './create/create-post-dialog.component';
import { AppPostListComponent } from './list/post-list.component';
import { AppPostCardComponent } from './card/post-card.component';

@NgModule({
    declarations: [
        AppPostTabComponent,
        AppCreatePostDialogComponent,
        AppPostListComponent,
        AppPostCardComponent
    ],
    imports: [
        AppSharedModule,
        AppPostTabRoutingModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatPaginatorModule,
        MatCardModule
    ],
    entryComponents: [AppCreatePostDialogComponent]
})
export class AppPostTabModule {
}
