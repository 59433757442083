import {
    Component,
    ChangeDetectionStrategy
} from '@angular/core';

@Component({
    templateUrl: './grammar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppGrammarTabComponent {
}
