import { NgModule } from '@angular/core';

import { MatTabsModule } from '@angular/material';

import { AppSharedModule } from '../../shared/shared.module';
import { AppDashboardRoutingModule } from './dashboard-routing.module';
import { AppClassTabModule } from './tabs/class';
import { AppDictTabModule } from './tabs/dict';
import { AppGrammarTabModule } from './tabs/grammar';
import { AppPostTabModule } from './tabs/post';
import { AppDashboardComponent } from './dashboard.component';

@NgModule({
    declarations: [
        AppDashboardComponent
    ],
    imports: [
        AppSharedModule,
        MatTabsModule,
        AppDashboardRoutingModule,
        AppClassTabModule,
        AppDictTabModule,
        AppGrammarTabModule,
        AppPostTabModule
    ]
})
export class AppDashboardModule {
}
