import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { finalize } from 'rxjs/operators';

import { AppApiService } from '../../shared/services';
import { AppSessionService } from '../../shared/services/session.service';
import { AppService } from '../../shared/services/app.service';

type DialogMode = 'signIn' | 'signUp';

@Component({
    templateUrl: 'login-dialog.component.html',
    styleUrls: ['login-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLoginDialogComponent {
    mode: DialogMode = 'signIn';

    formGroup = this.formBuilder.group({
        email: ['', [
            Validators.required,
            Validators.email
        ]],
        nickname: [{ value: '', disabled: true }, [
            Validators.required,
            Validators.minLength(4)
        ]],
        password: ['', [
            Validators.required,
            Validators.minLength(6)
        ]]
    });

    get email() {
        return this.formGroup.get('email');
    }

    get nickname() {
        return this.formGroup.get('nickname');
    }

    get password() {
        return this.formGroup.get('password');
    }

    constructor(
        private dialog: MatDialogRef<AppLoginDialogComponent>,
        private apiSvc: AppApiService,
        private sessionSvc: AppSessionService,
        private appSvc: AppService,
        private formBuilder: FormBuilder
    ) {
    }

    changeMode(mode: DialogMode) {
        this.mode = mode;
        this.password.reset();

        if (this.mode === 'signUp') {
            this.nickname.enable();
        } else {
            this.nickname.disable();
        }
    }

    onSignUp() {
        this.appSvc.showSpinner();

        this.apiSvc.signUp({
            ...this.formGroup.value,
            role: 'AUTHOR'
        }).pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            resp => {
                this.sessionSvc.signIn(resp.type, resp.token);
                this.dialog.close();
                this.appSvc.notifyInfo(
                    'Поздравляєм! Теперь Вы пользователь портала Lingvoinsta.'
                );
            },
            error => this.appSvc.notifyError(error.message)
        );
    }

    onSignIn() {
        this.appSvc.showSpinner();

        this.apiSvc.signIn(this.formGroup.value).pipe(
            finalize(() => this.appSvc.hideSpinner())
        ).subscribe(
            resp => {
                this.sessionSvc.signIn(resp.type, resp.token);
                this.dialog.close();
                this.appSvc.notifyInfo(
                    'Добро пожаловать!'
                );
            },
            error => this.appSvc.notifyError(error.message)
        );
    }
}
